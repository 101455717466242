const defaultComponentsProps = {
  MuiAppBar: {
    elevation: 0,
  },
  MuiButton: {
    disableRipple: true,
    disableElevation: true,
  },
  MuiButtonGroup: {
    disableRipple: true,
    disableElevation: true,
  },
  MuiCheckbox: {
    disableRipple: true,
  },
  MuiLink: {
    underline: 'hover',
  },
  MuiRadio: {
    disableRipple: true,
  },
  MuiTextField: {
    InputLabelProps: {
      shrink: true,
    },
  },
};

export default defaultComponentsProps;
